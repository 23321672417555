import React, { useContext, useEffect } from 'react'
import './connectedApp.styles.css';
import { SNEAKLOGO, BURGERICON } from '../../assets';

import AppBox from '../appbox/appbox.components';
import { SidebarContext } from '../../context/sidebar.context';
import { ScanLoginContext } from '../../context/scanLogin.context'
const ConnectedApp = () => {
  // let IMG_URL = 'https://xsgames.co/randomusers/assets/avatars/male/77.jpg'
  const { toggleSidebar, setIsSidebarOpen } = useContext(SidebarContext)
  const { connectedApps } = useContext(ScanLoginContext)

  useEffect(() =>{
    setIsSidebarOpen(true)
  }, [setIsSidebarOpen])

  return (
    <div className='connected-app'>
      <img src={BURGERICON} onClick={toggleSidebar} className='burger-icon' alt="" />
      <div className="connected-apps-container">
        <div className="conn-app">
          <div className="app-header">
            <img src={SNEAKLOGO} className='app-logo' alt="" />
            <h1>Sneak</h1>
          </div>
          <div className="conn-app-container">
            {/* Connected APP */}
            {connectedApps ? <>{
              Object.keys(connectedApps)?.map((accountKey) => {
                const account = connectedApps[accountKey]
                return (
                  <>
                    <AppBox
                      accountName={accountKey}
                      lastLogin={account.last_login}
                      virtualId={account.virtualid}
                      createdDate={account.create_date}
                      sessionStatus={account.session_status} />
                  </>
                )
              })
            }
            </> : <></>}

            {/* END - CONNECTED APP */}
          </div>
        </div>

      </div>
    </div>
  )
}

export default ConnectedApp