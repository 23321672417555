import React, { createContext, useState } from "react";
const SidebarContext = createContext()

const SideBarProvider = ({ children }) => {
	const [isSidebarOpen, setIsSidebarOpen] = useState(false)
	
	const toggleSidebar = () => {
		setIsSidebarOpen(!isSidebarOpen);
	}

	return (
		<SidebarContext.Provider value={{
			isSidebarOpen,
			setIsSidebarOpen,
			toggleSidebar
		}}>
			{children}
		</SidebarContext.Provider>
	)
}

export { SidebarContext, SideBarProvider};