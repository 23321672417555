import React, { useContext } from 'react';
import { ConnectedApp, Hero, CompanionWebsites, Scanner, Header, FeatureCard, Footer, Sidebar } from './components';
import { MOCK1, MOCK2, MOCK3, QRICON } from './assets';
import { ScanLoginContext } from './context/scanLogin.context';
import './App.css';

function App() {
  const { isAuthenticated } = useContext(ScanLoginContext)

  let FeaturesList = [
    {
      title: "Ad Blocker",
    },
    {
      title: "",
    },
    {
      title: "Anonymous Login",
    },
    {
      title: "Anonymous Payment",
    },
    {
      title: "ONDC Buyer",
    },
    {
      title: "Bundled Subscription",
    },
    {
      title: "",
    },
    {
      title: "Content Monetisation",
    },
  ]

  return (
    <div className="App">
      <Sidebar />
      <Header />

      {!isAuthenticated ?
        <>

          <div className="scanner-container">
            <div className="scanner-body">
            <Scanner />
            <div className='scan-content'>
              <h1>Use Sneak App to Scan</h1>
              <div className="hr-line"></div>
              <ul className="scan-content">
                  <li>1. Open Sneak App on your phone.</li>
                  <li className='qr-list'>2. Tap on <img src={QRICON} className='qr-icon' alt="" /> and Scan the Qrcode to login.</li>
              </ul>
            </div>
            </div>
          </div>
          <Hero />
          <CompanionWebsites />
          <div className="hero-bg">
            <img src={MOCK1} className='mock-1 mockup' alt="" />
            <img src={MOCK2} className='mock-2 mockup' alt="" />
            <img src={MOCK3} className='mock-3 mockup' alt="" />
          </div>
          <div className="feature-header">
            <h1>Our Features</h1>
          </div>
          <div className="feature-body">
            <div className="features-section">
              {FeaturesList.map((ft) => (
                <>
                  <FeatureCard featureTitle={ft.title} />
                </>
              ))}
            </div>
          </div>

        </>
        :
        <>
          <div className='app-container'>
            <ConnectedApp />
          </div>
        </>
      }
      <Footer />
    </div>
  );
}

export default App;
