import React from 'react'
import { TypeAnimation } from 'react-type-animation';
import './hero.styles.css';
import { VIDEOICON, PLAYStore } from '../../assets';

const Hero = () => {
  return (
    <div className='hero-container'>
      <div className='hero-content'>
        <h2>
          Stop
          <TypeAnimation
            sequence={[
              `Ads!`,
              1000,
              'Sharing Personal Info!',
              1000,
              'Login!',
              1000,
            ]}
            wrapper="span"
            speed={50}
            style={{ display: 'inline-block', fontWeight: '700', marginLeft: '.5rem', color: 'rgb(246, 96, 39)' }}
            repeat={Infinity}
          />
          <br />
          Just <span className='sneak-org'>Sneak</span>
        </h2>
        <div className="store-buttons">
          <a className="play-store store-btn" href='https://play.google.com/store/apps/details?id=com.abblor.sneak' target="_blank">
            <img src={PLAYStore} alt="" />
            <p>
              Play Store
            </p>
          </a>
          <a href='https://drive.google.com/file/d/1U3NVtYx0OZYyNMwxYXZSFpDYRO9U2Lcd/view?usp=drive_link' target='_blank' className="app-store store-btn">
            <img src={VIDEOICON} alt="" />
            <p>
              Demo
            </p>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Hero
