import React from 'react'
import './featurecard.styles.css';

const Featurecard = ({featureTitle}) => {
  return (
    <div className={`${'feature-card'} ${featureTitle === "" ? "transparent": ""}`}>
      <h2>
        {featureTitle}
      </h2>
    </div>
  )
}

export default Featurecard;