import React from 'react'
import { SNEAKLOGO } from '../../assets';
import './header.styles.css';

const Header = () => {
  return (
    <header>
      <div className="header-left">
        <a href="/">
          <img src={SNEAKLOGO} className='header-logo' alt="" />
          <h2>SNEAK</h2>
        </a>
      </div>
      <div className="header-right">
        <a href="#contact">Contact</a>
        <div>
                <a className='privacy-pol' href='/privacy-policy'>
              Privacy Policy</a>
            </div>
      </div>
    </header>
  )
}

export default Header