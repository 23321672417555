import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import PrivacyPolicy from "./pages/privacyPolicy/privacyPolicy.pages";
import reportWebVitals from "./reportWebVitals";
import { SideBarProvider } from "./context/sidebar.context";
import { ScanLoginProvider } from "./context/scanLogin.context";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <SideBarProvider>
        <ScanLoginProvider>
          <App />
        </ScanLoginProvider>
      </SideBarProvider>
    ),
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  },
]);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();
