import React, { useState, useContext } from 'react'
import { TOGGLE } from '../../assets';
import { ScanLoginContext } from '../../context/scanLogin.context';
// import Axios from '../../config/axios';
import './appbox.styles.css';
import axios from 'axios';

const GetFormattedDate = (timestamp) => {
	var date = new Date(timestamp);

	// Extract date components
	var day = date.getDate();
	var month = date.getMonth() + 1;
	var year = date.getFullYear() % 100;
	var hours = date.getHours();
	var minutes = date.getMinutes();

	return `${day}/${month}/${year} ${hours}:${minutes}`;
}

const Appbox = ({ accountName, lastLogin, virtualId, createdDate, sessionStatus }) => {
	// const { phoneNumber } = useContext(ScanLoginContext)
	const [isDetailsVisible, setDetailsVisible] = useState(false);
	const { phoneNumber, GetUserDetails } = useContext(ScanLoginContext);
	const toggleDetails = () => {
		setDetailsVisible(!isDetailsVisible)
	}

	const HandleDeleteAccount = async () => {
		const payload = {
			"phone": phoneNumber,
			"account": accountName,
			"app": "sneak"
		};
		const response = await axios.delete('https://api9.sneek.in/api/account/del', {
			data: payload, // Send payload as request body data
		});
		console.log(response.data);
		GetUserDetails()
	}


	// Create a new Date object
	return (
		<div className="conn-app-box" onClick={toggleDetails}>
			<div className="conn-app-header">
				<div className='conn-app-head-left'>
					<div className="conn-app-logo">
					</div>
					{/* <img src={APP_LOGO} className='app-logo' alt="" /> */}
					<h2>{accountName}</h2>
					<div className={`${'status-sign'} ${sessionStatus ? "status-true" : "status-false"}`}></div>
				</div>
				<div>
					<img src={TOGGLE} className='toggle' alt="" />
				</div>
			</div>
			<div className={`${"conn-app-details"} ${isDetailsVisible ? "display-block" : "display-none"}`}>
				<div className='app-info-box'>
					<label>Virtual ID:</label>
					<p>{virtualId}</p>
				</div>
				<div className='app-info-box'>
					<label>Creation Date:</label>
					<p>{GetFormattedDate(createdDate)}</p>
				</div>
				<div className='app-info-box'>
					<label>Last Login:</label>
					<p>{GetFormattedDate(lastLogin)}</p>
				</div>
				<div className='app-info-box'>
					<label>Access to:</label>
					<p>-Nil-</p>
				</div>
				<div className='app-info-box'>
					<label>Pending Payment:</label>
					<p>-Nil-</p>
				</div>
				<div className="app-info-box remove-accn">
					<button className='delete-account-btn' onClick={HandleDeleteAccount}>Delete Account</button>
				</div>
			</div>
		</div>
	)
}

export default Appbox