import React, { useContext } from 'react'
import { CLOSE } from '../../assets';
import './sidebar.styles.css';
import { SidebarContext } from '../../context/sidebar.context';
import { ScanLoginContext } from "../../context/scanLogin.context";

const Sidebar = () => {
  const { isSidebarOpen, toggleSidebar } = useContext(SidebarContext)
  const { name, age, username, phoneNumber, HandleLogout } = useContext(ScanLoginContext)

  // if (userDetails && phoneNumber) {
  //   console.log("inside sidebar", userDetails.associated_number[phoneNumber].connected_apps.sneak);
  // }

  let USER_IMG = "https://www.pngall.com/wp-content/uploads/5/User-Profile-PNG.png"
  return (
    <div className={`${'sidebar-container'} ${isSidebarOpen ? "" : "display-none"}`}>
      <div className="sidebar-header">
        <img src={CLOSE} onClick={toggleSidebar} className="close-btn" alt="" />
      </div>
      <div className="user-profile">
        <img src={USER_IMG} className='user-img' alt="" />
        <h2>Profile</h2>
      </div>
      {username ? <>
        <div className="user-details">
          <div className="det-con details-container">
            <label htmlFor="">Sneak UserId</label>
            <input type="text" placeholder={username} />
          </div>
          <div className="det-con details-container">
            <label htmlFor="">Phone No</label>
            <input type="text" placeholder={phoneNumber} />
          </div>
          <div className="det-con details-container">
            <label htmlFor="">Name</label>
            <input type="text" placeholder={name} />
          </div>
          <div className="det-con details-container">
            <label htmlFor="">Age Group:</label>
            <input type="text" placeholder={age} />
          </div>
          <div className="det-con details-container">
            <button onClick={HandleLogout}>Log Out</button>
          </div>
        </div>
      </> :
        <>
        </>
      }
    </div>
  )
}

export default Sidebar;