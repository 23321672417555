import React from "react";
import "./companionWebsites.styles.css";
import { WEBSITEICON } from "../../assets";

const CompanionWebsites = () => {
  return (
    <div className="hero-container">
      <div className="hero-content">
        <h3>Companion Websites</h3>
        <div className="store-buttons">
          <a className="website1 store-btn" href="https://w1.sneek.in/" target="_blank">
            <img src={WEBSITEICON} alt="" />
            <p>Website 1</p>
          </a>
          <a href="https://w2.sneek.in/" target="_blank" className="website2 store-btn">
            <img src={WEBSITEICON} alt="" />
            <p>Website 2</p>
          </a>
        </div>

        <div>
          <a
            className="survey-tagline shimmer"
            href="https://forms.gle/Ri23GybLVzC4Q1W19"
            target="_blank"
            rel="noreferrer"
          >
            Help us build a better product. Take Sneak Survey.
          </a>
        </div>
      </div>
    </div>
  );
};

export default CompanionWebsites;
