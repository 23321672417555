import React, { createContext, useEffect, useState } from "react";
import Axios from '../config/axios';
import Cookies from 'js-cookie';

const ScanLoginContext = createContext()

const ScanLoginProvider = ({ children }) => {
	const [token, setToken] = useState("")
	const [browserId, setBrowserId] = useState()
	// XbGC17DJm9dzisFLOV8B
	const [userId, setUserId] = useState(Cookies.get("uid"))
	const [userDetails, setUserDetails] = useState([])
	const [connectedApps, setConnectedApps] = useState()
	const [phoneNumber, setPhoneNumber] = useState("")
	const [name, setName] = useState("")
	const [username, setUsername] = useState()
	const [age, setAge] = useState()
	const [isAuthenticated, setIsAuthenticated] = useState(false)

	const RequestForBrowserId = async () => {
		if (Cookies.get("browserId") === undefined) {
			const response = await Axios.post("/api/v3/browserId", {
				ip: "0.0.0.0",
				websiteid: "sneak"
			})
			Cookies.set("browserId", response.data.browserId)
			setBrowserId(response.data.browserId)
		}
		setBrowserId(Cookies.get("browserId"))
	}

	const GenerateBrowserToken = async () => {
		const response = await Axios.post('/api/v3/gen/token', {
			browserId: browserId,
			ip: "0.0.0.0",
			website: "sneak",
		})
		setToken(response.data.token)
	}

	const RetreiveFreshToken = async () => {
		if (Cookies.get("browserId") !== undefined) {
			let brId = Cookies.get("browserId")
			const response = await Axios.post("/api/v3/latest/details", {
				"browserId": brId,
				"ip": "0.0.0.0",
				"website": "sneak"
			})
			if (response.data.token !== undefined) {
				setToken(response.data.token)
			}
		}
	}
	setInterval(async () => {
		await RetreiveFreshToken()
	}, 10000)

	// need to keep the user Id also as a cookie, all function in home page 
	// needed
	const RestfulConnection = async () => {
		console.log("Connection Check. in Session")
		if (token && Cookies.get("browserId") !== undefined) {
			let brId = Cookies.get("browserId")
			try {
				const response = await Axios.get("/api/v2/session/" + brId)
				console.log(response.data)
				if (response.data.session_status) {
					Cookies.set("uid", response.data.uid)
					setIsAuthenticated(true)
					setUserId(response.data.uid)
				}
			}catch (err) {
				console.log("Err: RestfulConnection function")
			}
		}
	}


	const GetUserDetails = async () => {
		console.log("User Details")
		try {
			if (userId) {
				await Axios.get("/api/v1/number/" + userId)
					.then(res => {
						Cookies.set("phone", res.data.phone)
						setPhoneNumber(res.data.phone)
						setIsAuthenticated(true)
					})
					.catch(err => console.error(err))


				await Axios.get("/api/" + userId)
					.then(res => {
						setUserDetails(res.data)
						setConnectedApps(res.data.associated_number[Cookies.get("phone")].connected_apps.sneak.connected_accounts)
						setName(res.data.associated_number[Cookies.get("phone")].connected_apps.sneak.name)
						setAge(res.data.associated_number[Cookies.get("phone")].connected_apps.sneak.age)
						setUsername(res.data.associated_number[Cookies.get("phone")].connected_apps.sneak.username)
					})
					.catch(err => console.error(err))

			}
		} catch (err) {
			console.log(err)
		}
	}

	const HandleLogout = () => {
		const cookies = Cookies.get();

		const uid = Cookies.get("uid")
		const phone = Cookies.get("phone")

		const RemoveServerSession = async () => {
			try {
				const response = await Axios.delete("/api/v2/session/remove", {
					browser_id: browserId,
					phone: phone,
					uid: uid,
					account: "sneak",
				})
				console.log(response.data)
			}catch(err) {
				console.log(err)
			}
		}
		RemoveServerSession()
		setIsAuthenticated(false)
		
		for (const cookie in cookies) {
			Cookies.remove(cookie);
		}

		// Delete the session from the server too
		console.log('All cookies cleared.');
		window.location.reload();
		// navigate to home
	}



	useEffect(() => {
		// Set BrowserId
		RequestForBrowserId()

		// Generate Fresh token for QrCode
		GenerateBrowserToken()


		const interval = setInterval(GetUserDetails, 5000);
		return () => {
			clearInterval(interval)
		};
	}, [])

	useEffect(() => {
		const sessionInterval = setInterval(RestfulConnection, 2000)
		return () => clearInterval(sessionInterval)
	})

	return (
		<ScanLoginContext.Provider value={{ token, HandleLogout, connectedApps, phoneNumber, name, age, username, isAuthenticated, GetUserDetails }}>
			{children}
		</ScanLoginContext.Provider>
	)
}

export { ScanLoginContext, ScanLoginProvider };