import React from "react";
import "./footer.styles.css";
import { LOGOABR } from "../../assets";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer-contents" id="contact">
        <div className="footer-line"></div>
        <div className="footer-sub-content1">
          <div class="logo">
            <a href="https://abblor.com" target="_blank" rel="noreferrer">
              <img src={LOGOABR} className="footer-logo" alt="" />
            </a>
            <a href="https://abblor.com" target="_blank" rel="noreferrer">
              <h3>Abblor</h3>
            </a>
          </div>

          <div class="footer-links">
            <div className="footer-links-head1">
              <div>
                <a
                  className="privacy-pol"
                  href="https://forms.gle/jgBsYJx97ihBCKkd7"
                  target="_blank"
                  rel="noreferrer"
                >
                  Submit Delete Account Request
                </a>
              </div>
            </div>

            <div id="mc_embed_shell">
              <div id="mc_embed_signup">
                <form
                  action="https://abblor.us21.list-manage.com/subscribe/post?u=78401b59a214854f05d2d103f&amp;id=9e35a110b9&amp;f_id=0030e2e6f0"
                  method="post"
                  id="mc-embedded-subscribe-form"
                  name="mc-embedded-subscribe-form"
                  class="validate "
                  target="_self"
                  novalidate=""
                >
                  <div id="mc_embed_signup_scroll" class="subscribe-form">
                    <p class="subscribe-tagline shimmer">Don't Miss Out! Stay in the Loop with Our Exclusive Updates.</p>
                    <div class="mc-field-group"><label for="mce-EMAIL">
                    </label>
                      <div style={{ display: "flex", flexDirection: "row", }}>
                        <input type="email" name="EMAIL" class="required email" id="mce-EMAIL" placeholder='Enter email address...' require /> <div class="optionalParent">
                          <div class="clear foot">
                            <input
                              type="submit"
                              name="subscribe"
                              id="mc-embedded-subscribe"
                              class="button subscribe-button"
                              value="Subscribe"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      hidden=""
                      style={{
                        visibility: "hidden",
                        width: "0px",
                        height: "0px",
                        position: "absolute",
                      }}
                    >
                      <input type="hidden" name="tags" value="2822243"></input>{" "}
                    </div>
                    <div
                      id="mce-responses"
                      class="clear foot"
                      style={{
                        visibility: "hidden",
                        width: "0px",
                        height: "0px",
                        position: "absolute",
                      }}
                    >
                      <div class="response" id="mce-error-response"></div>
                      <div class="response" id="mce-success-response"></div>
                    </div>
                    <div
                      aria-hidden="true"
                      style={{
                        visibility: "hidden",
                        width: "0px",
                        height: "0px",
                        position: "absolute",
                      }}
                    >
                      {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
                      <input
                        type="text"
                        name="b_78401b59a214854f05d2d103f_9e35a110b9"
                        tabindex="-1"
                        value=""
                        style={{ visibility: "hidden" }}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div className="connect">
              <a href="/">info@abblor.com</a>
            </div>
          </div>
        </div>
        <div className="end-contents">
          <p>
            <span>© Copyright 2023.</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
