import React, { useContext } from 'react'
import { QRCode } from 'react-qrcode-logo';
import { ScanLoginContext } from '../../context/scanLogin.context';
import { LOGO } from '../../assets';
import './scanner.styles.css';

const Scanner = () => {
  const { token } = useContext(ScanLoginContext);

  return (
    <div className='scanner-cnt'>
      <QRCode value={token} size={"210"} logoImage={LOGO} logoHeight={"70"} logoWidth={"70"}/>
    </div>
  )
}

export default Scanner